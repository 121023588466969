<template>
  <div ref="root" class="circle-loader">
    <svg
      class="circle-loader__progress-ring"
      width="120"
      height="120"
      viewBox="0 0 120 120"
    >
      <circle class="progress-ring__bg" cx="60" cy="60" r="60" fill="#FFECD2" />
      <g class="progress-ring__wrapper">
        <circle
          opacity=".2"
          r="6.5"
          transform="matrix(-1 0 0 1 44 42)"
          stroke="#D8635F"
          fill="transparent"
        />

        <circle
          class="progress-ring__circle"
          stroke="#D8635F"
          stroke-width="2.2"
          fill="transparent"
          r="48"
          cx="60"
          cy="60"
        />
        <circle
          class="progress-ring__circle-front"
          stroke="#D8635F"
          stroke-width="2.2"
          fill="transparent"
          r="48"
          cx="60"
          cy="60"
        />
        <g
          fill="none"
          opacity=".2"
          stroke="#D8635F"
          stroke-width="1.098"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M14.241 58.822c18.84 1.765 64.018 4.237 92.946 0M18.088 59.18l14.641-2.449 16.602 4.042 16.495-4.042 20.067 4.042 10.446-4.042 7.232 2.574"
          />
        </g>
      </g>
    </svg>
    <div class="circle-loader__img-container">
      <img
        class="circle-loader__img"
        :class="imgUrl.class"
        :src="imgUrl.url"
        alt=""
      />
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, computed, watchEffect } from 'vue'

import anime from 'animejs/lib/anime.es.js'
import store from '@/store'
import { useHowler } from '@/plugins/Howler'
import { FX_URL } from '@/webgl/config'

export default {
  name: 'CircleLoader',
  props: {
    appear: {
      type: Number,
      default: 0
    },
    dynamic: {
      type: Boolean,
      default: false
    }
  },
  emits: ['loaded'],
  setup(props, { emit }) {
    const howler = useHowler()
    const root = ref(null)
    const progress = ref(0)
    const imgUrl = computed(() => {
      const i = Math.floor(Math.random() * 4)
      return {
        url: `/assets/images/loader/loader-${i}.png`,
        class: `circle-loader__img--${i}`
      }
    })

    const soundEnd = howler.$getSound('cycle-end', false, {
      srcs: [FX_URL + 'cycle-end.mp3'],
      preload: true,
      preloadCallback: () => {},
      html5: false
    })

    onMounted(() => {
      onEnter()
    })

    const onEnter = () => {
      const el = root.value
      const bg = el.querySelector('.progress-ring__bg')
      const wrapper = el.querySelector('.progress-ring__wrapper')
      const img = el.querySelector('.circle-loader__img')
      const progress = el.querySelector('.progress-ring__circle-front')

      const tl = anime.timeline({
        delay: props.appear,
        complete: () => {
          emit('loaded')
        }
      })
      tl.add({
        targets: bg,
        duration: 833,
        scale: [0, 1],
        easing: 'cubicBezier(0.72, 0.07, 0.26, 1)'
      })
        .add(
          {
            targets: wrapper,
            duration: 667,
            opacity: [0, 1],
            easing: 'linear'
          },
          0
        )
        .add(
          {
            targets: wrapper,
            duration: 833,
            scale: [0, 1],
            easing: 'cubicBezier(0.72, -0.13, 0.26, 1)'
          },
          30
        )
        .add(
          {
            targets: img,
            duration: 833,
            // scale: [0, 1],
            opacity: [0, 1],
            rotateZ: ['-10deg', 0],
            translateX: ['-100%', '-50%'],
            translateY: ['-50%', '-50%'],
            easing: 'cubicBezier(0.72, -0.13, 0.26, 1)'
          },
          100
        )

      if (props.dynamic) {
        watchEffect(() => {
          anime.set(progress, {
            strokeDashoffset:
              store.getters['game/progressResourcesLoaded'] * 302
          })
          if (store.getters['game/progressResourcesLoaded'] === 0) {
            soundEnd.play()
          }
        })
      } else {
        tl.add(
          {
            targets: progress,
            duration: 7000,
            easing: 'linear',
            strokeDashoffset: 0,
            complete: () => {
              soundEnd.play()
            }
          },
          1000
        )
      }
    }

    const onLeave = () => {
      const el = root.value
      const bg = el.querySelector('.progress-ring__bg')
      const wrapper = el.querySelector('.progress-ring__wrapper')
      const img = el.querySelector('.circle-loader__img')
      const progress = el.querySelector('.progress-ring__circle-front')

      const tl = anime.timeline()
      tl.add(
        {
          targets: bg,
          duration: 833,
          scale: 0,
          easing: 'cubicBezier(0.72, 0.07, 0.26, 1)'
        },
        80
      )
        .add(
          {
            targets: wrapper,
            duration: 833,
            scale: 0,
            easing: 'cubicBezier(0.72, -0.13, 0.26, 1)'
          },
          30
        )
        .add(
          {
            targets: img,
            duration: 633,
            scale: 0.7,
            // scale: [0, 1],
            opacity: 0,
            rotateZ: '10deg',
            easing: 'cubicBezier(0.72, -0.13, 0.26, 1)'
          },
          0
        )
    }

    return { progress, imgUrl, onMounted, root, onLeave }
  }
}
</script>

<style lang="scss" scoped>
.circle-loader {
  width: 118px;
  height: 118px;
  position: relative;

  &__progress-ring {
    width: 100%;
    height: 100%;

    circle,
    g {
      transform-origin: 50% 50%;
    }

    .progress-ring__circle {
      opacity: 0.2;
    }

    .progress-ring__circle-front {
      stroke-dasharray: 302 302;
      stroke-dashoffset: 302;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
    }
  }

  &__img-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-49%, -49%);
    width: 80%;
    height: 80%;
    overflow: hidden;
    border-radius: 50%;
  }

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    max-height: 50px;

    &--0 {
      max-height: 62px;
    }
    &--1 {
      max-height: 45px;
      margin-left: -8px;
    }
    &--2 {
      max-height: 40px;
    }
    &--3 {
      max-height: 48px;
    }
  }
}
</style>
