
import { onMounted, ref, computed, watchEffect } from 'vue'

import anime from 'animejs/lib/anime.es.js'
import store from '@/store'
import { useHowler } from '@/plugins/Howler'
import { FX_URL } from '@/webgl/config'

export default {
  name: 'CircleLoader',
  props: {
    appear: {
      type: Number,
      default: 0
    },
    dynamic: {
      type: Boolean,
      default: false
    }
  },
  emits: ['loaded'],
  setup(props, { emit }) {
    const howler = useHowler()
    const root = ref(null)
    const progress = ref(0)
    const imgUrl = computed(() => {
      const i = Math.floor(Math.random() * 4)
      return {
        url: `/assets/images/loader/loader-${i}.png`,
        class: `circle-loader__img--${i}`
      }
    })

    const soundEnd = howler.$getSound('cycle-end', false, {
      srcs: [FX_URL + 'cycle-end.mp3'],
      preload: true,
      preloadCallback: () => {},
      html5: false
    })

    onMounted(() => {
      onEnter()
    })

    const onEnter = () => {
      const el = root.value
      const bg = el.querySelector('.progress-ring__bg')
      const wrapper = el.querySelector('.progress-ring__wrapper')
      const img = el.querySelector('.circle-loader__img')
      const progress = el.querySelector('.progress-ring__circle-front')

      const tl = anime.timeline({
        delay: props.appear,
        complete: () => {
          emit('loaded')
        }
      })
      tl.add({
        targets: bg,
        duration: 833,
        scale: [0, 1],
        easing: 'cubicBezier(0.72, 0.07, 0.26, 1)'
      })
        .add(
          {
            targets: wrapper,
            duration: 667,
            opacity: [0, 1],
            easing: 'linear'
          },
          0
        )
        .add(
          {
            targets: wrapper,
            duration: 833,
            scale: [0, 1],
            easing: 'cubicBezier(0.72, -0.13, 0.26, 1)'
          },
          30
        )
        .add(
          {
            targets: img,
            duration: 833,
            // scale: [0, 1],
            opacity: [0, 1],
            rotateZ: ['-10deg', 0],
            translateX: ['-100%', '-50%'],
            translateY: ['-50%', '-50%'],
            easing: 'cubicBezier(0.72, -0.13, 0.26, 1)'
          },
          100
        )

      if (props.dynamic) {
        watchEffect(() => {
          anime.set(progress, {
            strokeDashoffset:
              store.getters['game/progressResourcesLoaded'] * 302
          })
          if (store.getters['game/progressResourcesLoaded'] === 0) {
            soundEnd.play()
          }
        })
      } else {
        tl.add(
          {
            targets: progress,
            duration: 7000,
            easing: 'linear',
            strokeDashoffset: 0,
            complete: () => {
              soundEnd.play()
            }
          },
          1000
        )
      }
    }

    const onLeave = () => {
      const el = root.value
      const bg = el.querySelector('.progress-ring__bg')
      const wrapper = el.querySelector('.progress-ring__wrapper')
      const img = el.querySelector('.circle-loader__img')
      const progress = el.querySelector('.progress-ring__circle-front')

      const tl = anime.timeline()
      tl.add(
        {
          targets: bg,
          duration: 833,
          scale: 0,
          easing: 'cubicBezier(0.72, 0.07, 0.26, 1)'
        },
        80
      )
        .add(
          {
            targets: wrapper,
            duration: 833,
            scale: 0,
            easing: 'cubicBezier(0.72, -0.13, 0.26, 1)'
          },
          30
        )
        .add(
          {
            targets: img,
            duration: 633,
            scale: 0.7,
            // scale: [0, 1],
            opacity: 0,
            rotateZ: '10deg',
            easing: 'cubicBezier(0.72, -0.13, 0.26, 1)'
          },
          0
        )
    }

    return { progress, imgUrl, onMounted, root, onLeave }
  }
}
